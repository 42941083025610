import { Html } from "..";
import useSettings from "../useSettings";
import { useCustomControls } from "../portfolioView/customControls";
import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import {
  RENDER_TYPE,
  ROOF_TYPE,
  SOURCE_OFFSET,
  UPWARDS_QUATERNION_OFFSET,
} from "../constants";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { GiSelect } from "react-icons/gi";
import { ImExit } from "react-icons/im";
import { IconContext } from "react-icons/lib";

export default function Settings() {
  const [projects, setProjects] = useState([]);
  const curProject = useSettings((state) => state.curProject);
  const setCurProject = useSettings((state) => state.setCurProject);
  const { camera } = useThree();
  const controls = useThree((state) => state.controls);
  const customControls = useCustomControls((state) => state.controls);
  const showSettings = useSettings((state) => state.showSettings);
  const setRoof = useSettings((state) => state.setRoof);
  const setRender = useSettings((state) => state.setRender);
  const getTranslation = useSettings((state) => state.getTranslation);

  useEffect(() => {
    fetch("/getProjectNames")
      .then((res) => res.json())
      .then((names) => {
        setProjects(names);
      });
  }, []);

  const onChange = (o, e) => {
    if (e.target != null && e.target.innerHTML != "") {
      setCurProject(e.target.innerHTML);
    }
  };

  const onExit = () => {
    controls.enabled = false;
    customControls.current.animateCameraRotation(
      camera.position.clone().setY(camera.position.y + 20),
      1000,
      UPWARDS_QUATERNION_OFFSET,
      () => {
        setRender(RENDER_TYPE.BOTH);
        customControls.current.moveTo(SOURCE_OFFSET, 2, () => {
          customControls.current.moveBack(2, () => {
            setRoof(ROOF_TYPE.SHOULD);
            customControls.current.state.active = true;
          });
        });
      }
    );
  };

  return (
    <Html.In>
      <IconContext.Provider value={{ className: "settings-icons" }}>
        <div id="settings">
          {/* Color the icons */}
          <GiSelect />
          <Dropdown onSelect={onChange}>
            <Dropdown.Toggle id="dropdown-autoclose-true">
              {curProject}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {projects.map((name, i) => (
                <Dropdown.Item>{name}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <ImExit />
          <Button onClick={onExit} variant="danger">
            {getTranslation("exit")}
          </Button>
        </div>
      </IconContext.Provider>
    </Html.In>
  );
}
