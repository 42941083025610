import * as THREE from "three";
import React, {  RefObject } from "react";
import { useThree } from "@react-three/fiber";
import { Vector3 } from "three";
import { create } from "zustand";
import { PointerLockControls } from '@react-three/drei'
import { PointerLockControls as PointerLockControlsImpl } from 'three-stdlib';
import useSettings from "../useSettings.js";

const { PORTFOLIO_START_OFFSET, TO_SCALE } = require("../constants");


/*
animateCameraRotation() lerps the current camera quaternion to
the one which would look at a given location
export function animateCameraRotation(
  camera : THREE.Camera,
  distance : number,
  location : THREE.Vector3,
  time : number,
  callback : () => any,
) {
  var origpos = new THREE.Vector3().copy(camera.position); // original position
  var origrot = new THREE.Euler().copy(camera.rotation);
  camera.lookAt(location);

  var dstrot = new THREE.Euler().copy(camera.rotation);
  var vector = new THREE.Vector3();
  camera.getWorldDirection(vector);
  const displacement = new Vector3().copy(vector).multiplyScalar(distance * 20);
  const movePos = new Vector3().copy(camera.position).add(displacement);

  camera.position.set(origpos.x, origpos.y, origpos.z);
  camera.rotation.set(origrot.x, origrot.y, origrot.z);

  var qa = new THREE.Quaternion().copy(camera.quaternion);
  var qb = new THREE.Quaternion().setFromEuler(dstrot); // dst quaternion
  var qm = new THREE.Quaternion();
  var startTime = performance.now();

  var updateCameraPos = function () {
    let elapsed = performance.now() - startTime;
    let t = Math.min(elapsed / time, 1);
    camera.position.lerp(movePos, t);
    if (elapsed < time) {
      requestAnimationFrame(updateCameraPos);
    } else if (callback) {
      callback();
    }
  };

  var updateCameraRot = function () {
    let elapsed = performance.now() - startTime;
    let t = Math.min(elapsed / time, 1);
    qm.slerpQuaternions(qa, qb, t);
    camera.quaternion.set(qm.x, qm.y, qm.z, qm.w);
    if (elapsed < time) {
      requestAnimationFrame(updateCameraRot);
    } else {
      //startTime = performance.now();
      //requestAnimationFrame(updateCameraPos);
      moveTo(camera, movePos, time, callback);
    }
  };

  requestAnimationFrame(updateCameraRot);
}
*/

/*
extendCamera() extend the camera by a specific distance
by its current direction
*/
export function extendCamera(camera: THREE.Camera, amount : number) {
  var vector = new THREE.Vector3();
  camera.getWorldDirection(vector);
  const displacement = new Vector3().copy(vector).multiplyScalar(amount * 20);
  return new Vector3().copy(camera.position).add(displacement);
}


export const useCustomControls = create((set, get) => ({
  controls: null,
  setControls: (controls : RefObject<CustomControlsImpl>) => set({ controls }),
}));


interface CustomControlsProps {
  camera: THREE.Camera;
  gl: THREE.WebGLRenderer;
  invalidate: () => any,
  showSettings: (anount : number) => void,
  hideSettings:(amount : number)=> void,
}

interface CustomControlStates {
  pointerLockControls: PointerLockControlsImpl;
  active: boolean;
  startPage: boolean;
}

const CustomControls = React.forwardRef<CustomControlsImpl, CustomControlsProps>((props, ref) => {
  const {camera, invalidate, gl} = useThree();
  const showSettings = useSettings((state : any) => state.showSettings);
  const hideSettings = useSettings((state : any) => state.hideSettings);
  return <CustomControlsImpl hideSettings={hideSettings} showSettings={showSettings}
   invalidate={invalidate} camera={camera} gl={gl}  ref={ref} />
});

export default CustomControls;

/*
CustomControls() is a custom implementation of the
pointer lock controls including zooming at walls 
*/
class CustomControlsImpl extends React.Component<CustomControlsProps, CustomControlStates> {
  constructor(props: CustomControlsProps) {
    super(props);
    this.state = {
      pointerLockControls: new PointerLockControlsImpl(props.camera, props.gl.domElement),
      active: false,
      startPage: true,
    }
  }
  // frameloop demand performance stuff
  private callback = (e: THREE.Event) => {
    this.props.invalidate()
  }
  /*
  animateCameraRotation() lerps the current camera quaternion to
  the one which would look at a given location. If a specific destination
  rotation is giving, it will use this one instead.
  */
  animateCameraRotation(
    location: THREE.Vector3,
    time : number,
    dstrot? : THREE.Euler,
    callback? : () => void,
  ) {
    var origpos = new THREE.Vector3().copy(this.props.camera.position); // original position
    var origrot = new THREE.Euler().copy(this.props.camera.rotation);
    if (dstrot == null) {
      this.props.camera.lookAt(location);
      dstrot = new THREE.Euler().copy(this.props.camera.rotation);
    }
    var vector = new THREE.Vector3();
    this.props.camera.getWorldDirection(vector);

    this.props.camera.position.set(origpos.x, origpos.y, origpos.z);
    this.props.camera.rotation.set(origrot.x, origrot.y, origrot.z);

    var qa = new THREE.Quaternion().copy(this.props.camera.quaternion);
    var qb = new THREE.Quaternion().setFromEuler(dstrot); // dst quaternion
    var qm = new THREE.Quaternion();
    var startTime = performance.now();

    var updateCameraRot = function (camera: THREE.Camera, invalidate : any) {
      let elapsed = performance.now() - startTime;
      let t = Math.min(elapsed / time, 1);
      qm.slerpQuaternions(qa, qb, t);
      camera.quaternion.set(qm.x, qm.y, qm.z, qm.w);
      invalidate();
      if (elapsed < time) {
        requestAnimationFrame(() => {
          updateCameraRot(camera, invalidate)});
      } else if (callback) {
        callback();
      }
    };
    requestAnimationFrame(() => {
      updateCameraRot(this.props.camera, this.props.invalidate)});
  }


  /*
  moveBack() always moves back to the Portfolio start offset
  */
  private moveBack(speed = 2, callback? : () => void) {
    this.moveTo(PORTFOLIO_START_OFFSET, speed, callback);
  }

  /*
  moveTo() moves the camera to a specific position with a constant speed
  */
  private moveTo(movePos : THREE.Vector3, speed = 2, callback?: () => void) {
    var startTime = performance.now();
    var updateCameraPos = function(camera : THREE.Camera, invalidate : any) {
      let elapsed = (performance.now() - startTime) / 1000;
      const distance = camera.position.distanceTo(movePos);
      let t = Math.min(1, (elapsed * speed) / distance);
      camera.position.lerp(movePos, t);
      invalidate();
      if (t < 1) {
        requestAnimationFrame(() => {
          updateCameraPos(camera, invalidate)});
      } else if (callback) {
        callback();
      }
    };
    requestAnimationFrame(() => {
      updateCameraPos(this.props.camera, this.props.invalidate)});
  }

  // Custom pointer controls impl
  private handleFocus = (e : any) => {
    if (!this.state.active) return;
    if (e.target.onclick !=null) return;
    if (this.state.pointerLockControls.isLocked)
    {
      // disable controls
      this.props.showSettings(1);
      var vector = new THREE.Vector3();
      this.state.pointerLockControls.getDirection(vector);
      var angle = THREE.MathUtils.radToDeg(Math.atan2(vector.x, vector.z));
      var yAngle = THREE.MathUtils.radToDeg(vector.y);
      var amount = 0.8;
      var location;
      if (yAngle > 45) {
        // Roof
        amount = 0;
        location = new THREE.Vector3(1 * 20, 1.5 * 20, 1.49 * 20);
      } else if (angle < 137 && angle > 50) {
        location = new THREE.Vector3(3 * 20, 1 * 20, 1.5 * 20);
      } else if (angle < 50 && angle > -40) {
        // CV
        location = new THREE.Vector3(1 * 20, 1 * 20, 2.7 * 20);
      } else if (angle < -40 && angle > -133) {
        // About Proj
        location = new THREE.Vector3(0.5 * 20, 1 * 20, 1.5 * 20);
      } else if (this.state.startPage) {
        // About me
        amount = 1.4;
        location = new THREE.Vector3(1 * 20, 1 * 20, 0.5 * 20);
      }
      if (location != null) {
        this.state.pointerLockControls.unlock();
        this.setState({active:false});
        this.animateCameraRotation(location, 500, undefined, () => {
          this.moveTo(
            extendCamera(this.props.camera, amount),
            TO_SCALE(0.5),
            () => {
              this.setState({active:true});
            }
          );
        });
      }
    } else {
      this.state.pointerLockControls.lock();
      if (this.props.camera.position.distanceTo(PORTFOLIO_START_OFFSET) != 0) {
        this.props.hideSettings(1);
      }
      this.moveBack(2);
    }
  }

  componentWillUnmount(): void {
    // remove events
    this.state.pointerLockControls.removeEventListener('change', this.callback)
    document.addEventListener("click", this.handleFocus);
  }


  componentDidMount() {
    // add events
    this.state.pointerLockControls.addEventListener('change', this.callback)
    document.addEventListener("click", this.handleFocus);
  }
  render() {
    return(
    <primitive object={PointerLockControls}/>
    )
  }
}
/*
CustomControls() is a custom implementation of the
pointer lock controls including zooming at walls 
class CustomControls extends React.Component {
  constructor(props) {
    super(props);
    this.active = false;
  }

  componentDidMount() {
    const { camera, invalidate, gl } = useThree();
    const { zoom, render, startPage } = useSettings();
    const { setControls } = useCustomControls();
    const [active, setActive] = useState(false);

    const controlRef = useRef();

    // returning from sourceView
    useMemo(() => {
      // I choose a specific fov for the room,
      // because the proportions weren't as planned
      //camera.fov = 80;
      camera.far = 1000;
      camera.updateProjectionMatrix();
    }, [render]);

    useEffect(() => {
      camera.layers.enable(0);
      camera.layers.enable(1);
    }, [camera]);

    useEffect(() => {
      const handleFocus = (e) => {
        // controls activated?
        if (!active) return;
        // Roof star text
        if (e.target != null && e.target.className == "starsP") return;
        // Lock controls
        if (!controlRef.current.isLocked) {
          controlRef.current.lock();
          moveBack(camera);
        } else {
          // disable controls
          setActive(false);
          // get cam dir and check angles for walls
          var vector = new THREE.Vector3();
          controlRef.current.getDirection(vector);
          var angle = THREE.MathUtils.radToDeg(Math.atan2(vector.x, vector.z));
          var yAngle = THREE.MathUtils.radToDeg(vector.y);
          var amount = 0.8;
          var location;
          if (yAngle > 45) {
            // Roof
            amount = 0;
            location = new THREE.Vector3(1 * 20, 1.5 * 20, 1.49 * 20);
          } else if (angle < 137 && angle > 50) {
            location = new THREE.Vector3(3 * 20, 1 * 20, 1.5 * 20);
          } else if (angle < 50 && angle > -40) {
            // CV
            location = new THREE.Vector3(1 * 20, 1 * 20, 2.7 * 20);
          } else if (angle < -40 && angle > -133) {
            // About Proj
            location = new THREE.Vector3(0.5 * 20, 1 * 20, 1.5 * 20);
          } else if (startPage) {
            // About me
            amount = 1.6;
            location = new THREE.Vector3(1 * 20, 1 * 20, 0.5 * 20);
          }
          // zooming enabled?
          if (zoom) {
            animateCameraRotation2(camera, location, 500, null, () => {
              moveTo(
                camera,
                extendCamera(camera, amount),
                TO_SCALE(0.5),
                () => {
                  setActive(true);
                }
              );
            });
          } else {
            setControls(true);
          }
          controlRef.current.unlock();
        }
      };
      document.addEventListener("click", handleFocus);

      controlRef.current.addEventListener("change", invalidate);

      return () => {
        controlRef.current.removeEventListener("change", invalidate);
        document.removeEventListener("click", handleFocus);
      };
    }, [gl, active]);
  }

}
*/
