import { Html } from "..";
import { LANGS } from "../constants";
import useSettings from "../useSettings";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import { BsVolumeUp } from "react-icons/bs";
import { MdLanguage } from "react-icons/md";
import { IconContext } from "react-icons/lib";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useState } from "react";

const mailAddr = "alexander@vonmutius.me";

/*
Settings() displays volume and language settings and
the contact email as absolute element in all portfolio views.
*/
export default function Settings() {
  const { getTranslation, setLanguage, language, setVolume, volume, settings } =
    useSettings();

  const [show, setShow] = useState(false);

  const onLangChange = (o, e) => {
    if (e.target.innerHTML !== "") {
      setLanguage(e.target.innerHTML);
    }
  };

  const onVolumeChange = (e) => {
    setVolume(e.target.value);
  };

  const onMailClick = () => {
    setShow(true);
    navigator.clipboard.writeText(mailAddr);
    setTimeout(() => setShow(false), 2500);
  };

  if (settings != 0) return;

  return (
    <Html.In>
      {/* Color the icons */}
      <IconContext.Provider value={{ className: "settings-icons" }}>
        {/* Settings element */}
        <div id="settings">
          {/* Show a "Copied!" tooltip on click */}
          <OverlayTrigger
            trigger={"click"}
            placement="bottom"
            show={show}
            delay={{ hide: 250 }}
            overlay={<Tooltip>{getTranslation("copied")}</Tooltip>}
          >
            <a onClick={onMailClick}>{mailAddr}</a>
          </OverlayTrigger>
          <div class="volume-container">
            <BsVolumeUp />
            <Form.Range
              min={0}
              max={100}
              step={1}
              defaultValue={volume}
              onChange={onVolumeChange}
            />
          </div>
          <div class="lang-container">
            <MdLanguage />
            <Dropdown onSelect={onLangChange}>
              <Dropdown.Toggle id="dropdown-autoclose-true">
                {language}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {LANGS.map((name, i) => (
                  <Dropdown.Item key={"lang_" + name}>{name}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </IconContext.Provider>
    </Html.In>
  );
}
