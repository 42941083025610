/*
a map of all string translations in english and german
*/
const translations = {
  en: {
    lang: "Language:",
    volume: "Volume:",
    continue: "Continue",
    recommend:
      "I really recommend watching the presentation if you haven't seen it yet.",
    start: "Start",
    skip: "Skip",
    great: "Great!",
    me: `
            Welcome to my portfolio website!
            <br />
            My name is Alexander von Mutius, and I am a passionate software
            engineer with a wealth of experience. Over the course of my career,
            I have developed numerous full-stack projects, from designing logos
            to setting up backend servers with databases and interfaces for
            cross-platform shipping applications.
            <br />
            <br />
            Throughout my journey, I have used a diverse range of coding
            languages and tech stacks, working with all major operating systems.
            I have had the pleasure of managing my own team of employees and
            also working for employers serving major companies, where I have
            contributed to the development of their productive systems.
            <br /> I possess knowledge and expertise in DevOps methodologies. I
            have been involved in setting up DevOps for my own teams, ensuring
            smooth and efficient collaboration throughout the development
            process.
            <br />
            <br />
            Please feel free to browse my portfolio and get in touch if you
            would like to discuss any potential projects or collaborations.
            Thank you for stopping by!`,
    seeMore: "See more (recommended)",
    mobile: "Visit on desktop to see more!",
    safari:
      "Unforunately the presentation isn't available for your browser. You can change to f.ex. Chrome.",
    tellme: "Please tell me a name:",
    wait: "Please wait while the presentation is loading..",
    aiFailed: "AI Failed to connect. Presentation will load without it..",
    aiMutex: "The AI is being used right now. Waiting..",
    exit: "Exit",
    presentationAudio: "../audios/enPresentation.m4a",
    presentationModel: "../models/enPresentation_low.glb",
    presentationContinue: "Press spacebar..",
    presentation1:
      "Hello you are looking at a virtual version of me, Alexander von Mutius. Did you ask yourself, how could i spell your name without recording it before? Well this because i used an AI to synthesize my own voice.",
    presentation2:
      "I also used an AI to capture my motion or to get the idea for this room. Well i had to model it myself at the end which was the first and last time i did this. But.. I had the idea for this dragon there.",
    presentation3:
      "Anyways if you wanna get more information about me or this project, you can just find it in the room. By moving your mouse you can look around and with a left click, you can get closer to the walls and interact with them. But before you do this. ",
    presentation4:
      "I asked myself why would a software engineer just code a normal website. Well this is not a normal website for sure, but there's more to it. Please look at the ceiling and click on the sentence you see there.",
    roofText1: "Show me",
    roofText2: "the stars",
    privacy: "Note: Nothing is saved. This is just for presentation purposes.",
    projectDesc1:
      "I created this portfolio website to showcase my skills and abilities in the IT field." +
      "After being self-employed for a significant portion of my life, I realized that I wanted to" +
      "have more security and stability. I applied for jobs but found it challenging to convey my expertise " +
      "and capabilities without references. This project serves as a demonstration of my creativity, innovation, " +
      "and determination to bring any idea to life, using whatever necessary technology. " +
      "This project is nothing exceptional for me nor my biggest, but the ones i did, were probably " +
      "hard to understand for most employers without knowing the niche.",
    projectDesc2:
      "I spent two months working on my portfolio website, while also balancing other side projects. The purpose of this project " +
      "was to showcase my skills and creativity as a developer, as well as my willingness to learn and adapt to new technologies. " +
      "I chose to include a variety of coding languages such as <span>Go</span> for the backend, <span>React</span> for the frontend," +
      "<span> Perl</span> for text manipulation, " +
      "and <span>Python</span> for AI resources. I utilized a speech synthesis <span>Python</span> project (credits given) and adapted it to recognize German text " +
      'accent to match my voice. Additionally, I leveraged <span>AI</span> to generate an idea for this website basend on the prompt "3D room dark website UI"' +
      "and used motion capture to generate animations based on videos i recorded. I quickly learned to use Sketchup and Blender to 3D model" +
      "the AI-generated idea and employed my game and website development skills to assemble all the pieces for this portfolio project.",
    boreus: "Backend developer for Boreus GmbH",
    telekom: "Customer service of buisness partner for the Telekom",
    college: "Graduation technical college of economics",
    secondarySchool: "Graduation from secondary school",
    nodeGeneral: "General",
    nodeLinesOfCode: "Analyzed lines of code:",
    nodeDuplicates: "Code duplicates:",
    nodeComments: "Comment density:",
    nodeLang: "Languages",
    nodeQuality: "Code Quality",
    nodeComplexity: "Too complex functions:",
    nodeNaming: "Bad variable naming:",
    nodeTooLarge: "Too large functions",
    nodeTooLong: "Too long lines",
    nodeTooMany: "Too many arguments",
    copied: "Copied!",
  },
  de: {
    lang: "Sprache:",
    volume: "Lautstärke:",
    continue: "Weiter",
    recommend:
      "Ich würde empfehlen die Präsentation zu schauen falls Sie sie noch nicht gesehen haben.",
    start: "Starten",
    skip: "Überspringen",
    great: "Toll!",
    seeMore: "Mehr sehen (empfohlen)",
    mobile: "Wechseln Sie auf Desktop um mehr zu sehen!",
    safari:
      "Leider unterstützt Ihr Browser die Präsentation nicht. Wechseln Sie z.b. zu Chrome.",
    tellme: "Wähle einen Namen:",
    wait: "Bitte warten Sie während die Präsentation lädt..",
    aiFailed:
      "Konnte nicht zur KI verbinden. Präsentation wird ohne KI ausgeführt..",
    aiMutex: "Die KI wird bereits verwendet. Warten..",
    exit: "Verlassen",
    presentationAudio: "../audios/dePresentation.m4a",
    presentationModel: "../models/dePresentation_low.glb",
    presentationContinue: "Drücken Sie die Leertaste..",
    me: `
    Willkommen auf meiner Portfolio-Website!
    <br/>
    Mein Name ist Alexander von Mutius und ich bin ein leidenschaftlicher Software-Entwickler der schon reichlich Erfahrung sammeln konnte. 
    Ich hatte bereits die Chance einige Full-Stack-Projekte zu entwickeln, angefangen bei der Gestaltung von Logos bis hin zur Einrichtung
    von Backend-Server mit Datenbank und Schnittstellen für plattformübergreifende Anwendungen.
    <br/>
    <br/>
    Auf meinem Weg habe ich mehrere Programmiersprachen und Tech-Stacks nutzen können und mit allen nennenswerten Betriebssystemen gearbeitet. 
    Ich hatte sogar schon das Vergnügen, mein eigenes Team zu leiten aber auch für Arbeitgeber zu arbeiten, die große Unternehmen bedienen, 
    wo ich zur Entwicklung produktiver Systeme beigetragen habe oder eigene veröffentlichen konnte.
    <br/>
    Ich besitze auch fortgeschrittenes Fachwissen im DevOps Bereich, welches Ich mir beim Aufsetzen von Projekten mit meinem Team aneignete, um eine reibungslose und effiziente Zusammenarbeit
    während des Entwicklungsprozesses sicherzustellen.
    Bitte zögern Sie nicht, sich umzuschauen und bei mir zu melden, wenn Sie potenzielle Projekte oder Zusammenarbeiten besprechen möchten. Vielen Dank für Ihren Besuch!`,
    presentation1:
      "Hallo. Sie sehen die virtuelle Representation von Alexander von Mutius und das ist meine Welt. Sie sieht vielleicht noch ein wenig klein aus aber das ist mehr dahinter. Haben Sie sich gefragt wie ich Ihren Namen aussprechen konnte ohne den vorher aufzunehmen?",
    presentation2:
      "Das liegt daran, dass ich eine KI benutzt habe um meine eigene Stimme zu synthetisieren. Ich habe auch eine KI benutzt um meine Bewegung hier in diese Welt zu übertragen oder die Idee für diesen Raum zu bekommen. Ich musste am Ende natürlich den Raum selber modellieren, was das erste und auch das letzte Mal war, aber immerhin ist dieser Drache noch von mir.",
    presentation3:
      "Egal mehr Informationen zu diesem Raum oder zu mir, finden Sie innerhalb dieses Raum. Schauen Sie sich einfach mit Ihrer Maus um und benutzen Sie den Linksklick um näher an die Wänds ranzukommen um mit diesen zu Interagieren.",
    presentation4:
      "Aber bevor Sie das machen. Ich habe mich gefragt wieso sollte ein Softwareentwickler eine normale Webseite machen. Gut vielleicht ist diese nicht normal.. aber es gibt noch mehr. Gucken Sie bitte an die Decke und klicken Sie auf den Satz der dort steht.",
    roofText1: "Zeig mir",
    roofText2: "die Sterne",
    privacy:
      "Info: Keine Angaben auf dieser Webseite werden gespeichert. Dies dient nur zu Präsentationszwecken.",
    projectDesc1:
      "Ich habe diese Portfolio-Website erstellt, um meine Fähigkeiten im IT-Bereich zu präsentieren. " +
      "Nachdem ich lange Zeit selbstständig war, erkannte ich, dass ich mir mehr Sicherheit und Stabilität wünsche. " +
      "Ich bewarb mich um Jobs, aber es war schwierig, meine Kompetenzen und Fähigkeiten ohne Referenzen zu vermitteln. " +
      "Hiermit will ich meine Kreativität, Innovation und Entschlossenheit demonstrieren, jegliche Idee mit der " +
      "notwendigen Technologie zum Leben zu erwecken. Dieses Projekt ist nichts Außergewöhnliches für mich und auch nicht " +
      "mein größtes, jedoch sind meine Vergangenen wohl schwer zu verstehen für die meisten Arbeitgeber ohne Hintergrundwissen " +
      "bezüglich der Nische.",
    projectDesc2:
      "Ich habe zwei Monate damit verbracht dieses Project fertigzustellen, während meiner Arbeit an anderen Nebenprojekten. " +
      "Der Zweck dieses Projekts war, meine Fähigkeiten und Kreativität als Entwickler zu präsentieren sowie meine Bereitschaft, neue " +
      "Technologien zu lernen und anzupassen. Deswegen habe Ich mich dazu entschieden, eine Vielfalt an Programmiersprachen zu verwenden, " +
      "wie <span>Go</span> für den Backend-Teil, <span>React</span> für den Frontend-Teil, <span>Perl</span> für Textmanipulationen und " +
      "<span>Python</span> für KI-Ressourcen. Ich nutzte ein Sprachsynthese-Projekt in <span>Python</span> (mit Angabe der Quellen) und " +
      "passte es an, um deutsche Texte und Akzente zu erkennen und sie meiner Stimme anzupassen. Darüber hinaus nutzte ich <span>KI</span>, " +
      'um eine Idee für diese Website basierend auf dem Stichwort "3D room dark website UI" zu generieren und nutzte Motion Capture, um ' +
      "Animationen auf der Grundlage von aufgezeichneten Videos zu erstellen. Ich lernte schnell, wie man Sketchup und Blender nutzt, um das " +
      "von der KI generierte Konzept zu 3D-modellieren und mithilfe meiner Fähigkeiten in der Spiel- und Website-Entwicklung, setzte ich alle " +
      "Teile für dieses Portfolio-Projekt zusammen.",
    boreus: "Backend Entwickler für die Boreus GmbH",
    telekom: "Kundenservice für Geschäftskunden der Telekom",
    college: "Abschluss vom Fachgymnium mit Schwerpunkt Wirtschaft",
    secondarySchool: "Abschluss der Realschule",
    nodeGeneral: "Allgemein",
    nodeLinesOfCode: "Analysierte Codezeilen:",
    nodeDuplicates: "Code-Duplikate:",
    nodeComments: "Kommentardichte:",
    nodeLang: "Sprachen",
    nodeQuality: "Code Qualität",
    nodeComplexity: "Zu komplexe Funktionen:",
    nodeNaming: "Schlechte Variabelnamen:",
    nodeTooLarge: "Zu lange Funktionen:",
    nodeTooLong: "Zu lange Codezeilen:",
    nodeTooMany: "Zu viele Argumente:",
    copied: "Kopiert!",
  },
};

export default translations;
