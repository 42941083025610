/*
This file is just returning freely set lights
for the corresponding walls. Corner lights are 
also included in near walls.
*/

import { SpotLight } from "@react-three/drei";
import { isMobile } from "react-device-detect";
import { DirectionalLight } from "three";

function Start() {
  return (
    <group>
      <pointLight
        color={"white"}
        intensity={5}
        position={[0.3 * 20, 1.1 * 20, 0.1 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.2 * 20}
      />
      <pointLight
        color={"#967300"}
        intensity={20}
        position={[2.2 * 20, 0.7 * 20, 0.3 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.4 * 20}
      />
      <pointLight
        color={"white"}
        intensity={5}
        position={[1 * 20, 0.9 * 20, 0 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={2 * 20}
      />
      <pointLight
        color={"white"}
        intensity={12}
        position={[1 * 20, 1 * 20, 0.2 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={2}
        distance={1 * 20}
      />
    </group>
  );
}

function Lang() {
  return (
    <group>
      <pointLight
        color={"white"}
        intensity={7}
        position={[2.3 * 20, 1.7 * 20, 0.5 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.2 * 20}
      />
      <pointLight
        color={"white"}
        intensity={8}
        position={[2.3 * 20, 1 * 20, 1.5 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.4 * 20}
      />
      <pointLight
        color={"white"}
        intensity={7}
        position={[2.3 * 20, 1.9 * 20, 2.4 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.7 * 20}
      />
      <pointLight
        color={"white"}
        intensity={10}
        position={[2.3 * 20, 0.3 * 20, 1 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.2 * 20}
      />
    </group>
  );
}

function About() {
  return (
    <group>
      <pointLight
        color={"white"}
        intensity={7}
        position={[-0.3 * 20, 1 * 20, 1.5 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.5 * 20}
      />
      <pointLight
        color={"white"}
        intensity={7}
        position={[-0.3 * 20, 1.9 * 20, 1 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.5 * 20}
      />
      <pointLight
        color={"#967300"}
        intensity={20}
        position={[-0.1 * 20, 0.7 * 20, 2.6 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.4 * 20}
      />
    </group>
  );
}

function CV() {
  return (
    <group>
      <pointLight
        color={"white"}
        intensity={9}
        position={[0.5 * 20, 1.9 * 20, 2.7 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.2 * 20}
      />
      <pointLight
        color={"white"}
        intensity={7}
        position={[1.5 * 20, 0.2 * 20, 2.7 * 20]}
        castShadow
        shadow-bias={-0.01}
        decay={1.5}
        distance={1.2 * 20}
      />
    </group>
  );
}

export default function Lights() {
  return (
    <group>
      <Start />
      {!isMobile && (
        <>
          <Lang />
          <CV />
          <About />
        </>
      )}
    </group>
  );
}
