import React, { Suspense, useState, useEffect, useRef, useMemo } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Resizable } from "re-resizable";
import "bootstrap/dist/css/bootstrap.min.css";
import { Html } from "../index";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import atomDark from "react-syntax-highlighter/dist/esm/styles/prism/atom-dark";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import useSettings from "../useSettings";

function Info({ node }) {
  const { getTranslation } = useSettings();

  return (
    <div className="nodeInfoContainer">
      <div className="nodeInfo">
        <p id="nodeOptionName">{node.Name}</p>
        <p>{node.Description}</p>
        <p className="optionHeader">{getTranslation("nodeGeneral")}</p>
        {node.CodeInfo != null && (
          <div className="inlineCont">
            <p>
              {getTranslation("nodeLinesOfCode")}
              <span>{node.CodeInfo["CodeCnt"]}</span>
            </p>
            {/*
            TODO: Share duplicates accross the project to reduce false positives
            <p>
              {getTranslation("nodeDuplicates")}
              <span>{node.CodeInfo["Duplicates"]}</span>
            </p>
            */}
            <p>
              {getTranslation("nodeComments")}
              <span>
                {parseInt(
                  (node.CodeInfo["CommentCnt"] / node.CodeInfo["CodeCnt"]) * 100
                )}
                %
              </span>
            </p>
          </div>
        )}
        <p className="optionHeader">{getTranslation("nodeLang")}</p>
        {node.Languages != null &&
          Object.keys(node.Languages)
            .sort((a, b) => {
              return node.Languages[b] - node.Languages[a];
            })
            .map((key, index) => {
              if (key != "Total") {
                const percent = parseInt(
                  (node.Languages[key] / node.Languages["Total"]) * 100
                );
                return (
                  <group>
                    <p>{key}</p>
                    <ProgressBar now={percent} label={`${percent}%`} />
                  </group>
                );
              }
            })}
        <p className="optionHeader">{getTranslation("nodeQuality")}</p>
        {node.CodeInfo != null && (
          <div className="inlineCont">
            <p>
              {getTranslation("nodeComplexity")}
              <span>{node.CodeInfo["ComplexityCnt"]}</span>
            </p>
            <p>
              {getTranslation("nodeNaming")}
              <span>{node.CodeInfo["DirtyCodeCnt"]}</span>
            </p>
            <p>
              {getTranslation("nodeTooLarge")}
              <span>{node.CodeInfo["LongFuncCnt"]}</span>
            </p>
            <p>
              {getTranslation("nodeTooLong")}
              <span>{node.CodeInfo["LongLineCnt"]}</span>
            </p>
            <p>
              {getTranslation("nodeTooMany")}
              <span>{node.CodeInfo["ManyArgs"]}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

function GetLog({ node }) {
  const [fileContent, setFileContent] = useState();
  useMemo(() => {
    fetch(
      "/getLog?" +
        new URLSearchParams({
          file: node.FilePath,
        })
    )
      .then((res) => res.text())
      .then((body) => setFileContent(body));
  }, []);
  return <pre>{node.IsMod && fileContent}</pre>;
}

/*

*/
function GetCode({ node }) {
  const [fileContent, setFileContent] = useState();
  fetch(
    "/getCode?" +
      new URLSearchParams({
        file: node.FilePath,
      })
  )
    .then((res) => res.text())
    .then((body) => setFileContent(body));
  return (
    <pre id="codeView">
      {node.Languages != null && (
        <SyntaxHighlighter
          language={
            node.Languages &&
            Object.keys(node.Languages).length !== 0 &&
            Object.keys(node.Languages)[0].toLowerCase()
          }
          style={atomDark}
        >
          {fileContent}
        </SyntaxHighlighter>
      )}
    </pre>
  );
}

/*
Options() shows information and settings about version control
on the left side
*/
export default function Options({ node }) {
  const [key, setKey] = useState("info");

  return (
    /*Tunneling in options */
    <Html.In>
      <Resizable
        defaultSize={{ width: "500px", height: "100%" }}
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        className="nodeOptions"
      >
        <Tabs
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="nodeTabs"
          justify
        >
          <Tab eventKey="info" title="Info">
            <Info node={node} />
          </Tab>
          {!node.IsDir && (
            <Tab eventKey="code" title="Code">
              <GetCode node={node} />
            </Tab>
          )}
          {/* TODO: Add logs table
          {node.IsMod && (
            <Tab eventKey="logs" title="Logs">
              <GetLog node={node} />
            </Tab>
          )}
          */}
        </Tabs>
      </Resizable>
    </Html.In>
  );
}
