import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";
import * as THREE from "three";
import ProgressBar from "react-bootstrap/ProgressBar";
import { EYE_PATH, SCALP_PATH } from "../../constants";
import useSettings from "../../useSettings";
import { useMemo, useState } from "react";
import { MeshStandardMaterial } from "three";
import { useCustomControls } from "../customControls";

/*
convertMat() does needed adjustments to all materials.
*/
function convertMat(oldMat) {
  // We convert everything to MeshStandard for better performance.
  var mat = new MeshStandardMaterial();
  mat.copy(oldMat);
  // Shinyness
  mat.roughness = 1;
  // Light affecting material
  mat.metalness = 0.9;
  // These materials aren't probably shipped by blender (alpha textures)
  if (oldMat.name == "scalp") {
    const texture = new THREE.TextureLoader().load(SCALP_PATH);
    mat.alphaMap = texture;
    mat.transparent = true;
    mat.needsUpdate = true;
  } else if (oldMat.name == "Genesis 8 Male Eyelashes_Eyelashes") {
    const texture = new THREE.TextureLoader().load(EYE_PATH);
    mat.alphaMap = texture;
    mat.transparent = true;
    mat.needsUpdate = true;
  }
  return mat;
}

// The meshopt algo i'm using isn't returning the total size of the model
// so i need to hardcore it to show the status
const modelTotal = 128811004;

/*
LoadPresentation() loads the presentation model and shows the overall
loading process.
*/
export default function LoadPresentation({ socket, aiStatus, callback }) {
  const getTranslation = useSettings((state) => state.getTranslation);
  const setModel = useSettings((state) => state.setModel);
  const customControls = useCustomControls((state) => state.controls);
  const [modelStatus, setModelStatus] = useState(null);

  useMemo(() => {
    // We don't use r3f hooks since we are loading on demand (if user chose to)
    const loader = new GLTFLoader();
    loader.setMeshoptDecoder(MeshoptDecoder);
    loader.load(
      getTranslation("presentationModel"),
      (glb) => {
        glb.scene.traverse(function (object) {
          if (object.isMesh) {
            if (!Array.isArray(object.material)) {
              object.material = convertMat(object.material);
            } else {
              for (var i = 0; i < object.material.length; i++) {
                object.material[i] = convertMat(object.material[i]);
              }
            }
            object.castShadow = true;
            object.receiveShadow = true;
          }
        });
        setModelStatus(100);
        setModel(glb);
      },
      (e) => {
        // Mostly loading progress of gathering textures and resources into RAM.
        // Unfortunately once the presentation plays, it will still lag for
        // second(s) since then the presentation gets loaded by the GPU

        let status = parseInt((e.loaded / modelTotal) * 100);
        if (status != 100) {
          setModelStatus(status);
        }
      }
    );
  }, []);

  var total = 0;
  var message = "";
  if (socket == null) {
    message = "AI Failed to connect. Presentation will load without it..";
    if (modelStatus != null) {
      total = modelStatus;
      if (total == 100) {
        // AI and model have finished loading
        customControls.current.moveBack(2, () => {
          // AI and model have finished loading
          callback(false);
        });
      }
    } else if (aiStatus != null) {
      if (isNaN(aiStatus.status)) {
        message = getTranslation("aiMutex");
      } else if (modelStatus != null) {
        total = (parseInt(aiStatus.status) + modelStatus) / 2;
        if (total == 100) {
          customControls.current.moveBack(2, () => {
            // AI and model have finished loading
            callback(true);
          });
        }
      }
    }
  }
  return (
    <group>
      <p>{message == "" ? getTranslation("wait") : message}</p>
      <div className="progressCont">
        <ProgressBar variant="mine" animated now={total} label={`${total}%`} />
      </div>
      {modelStatus == "100" && (
        <button
          onClick={() => {
            socket.close();
            if (modelStatus == 100) {
              customControls.current.moveBack(2, () => {
                // AI and model have finished loading
                callback(false);
              });
            }
          }}
        >
          {getTranslation("skip")}
        </button>
      )}
    </group>
  );
}
