import { create } from "zustand";
import { RENDER_TYPE, ROOF_TYPE } from "./constants";
import translations from "./translations";

const useSettings = create((set, get) => ({
  language: "en",
  volume: 50,
  render: RENDER_TYPE.PORTFOLIO,
  roof: ROOF_TYPE.CLOSED,
  canTransition: true,
  settings: 0,
  inPresentation: false,
  model: null,
  curProject: "Portfolio",
  setLanguage: (language) => set({ language }),
  setVolume: (volume) => set({ volume }),
  setRender: (render) => set({ render }),
  setRoof: (roof) => set({ roof }),
  hideSettings: (amount) => set({ settings: get().settings + amount }),
  showSettings: (amount) => set({ settings: get().settings - amount }),
  setInPresentation: (inPresentation) => set({ inPresentation }),
  setModel: (model) => set({ model }),
  setCanTransition: (canTransition) => set({ canTransition }),
  setCurProject: (curProject) => set({ curProject }),
  getTranslation: (string) => {
    return translations[get().language][string];
  },
}));

export default useSettings;
