import useSettings from "../../useSettings";
import React, { useState } from "react";
import { useCustomControls } from "../customControls";
import LoadPresentation from "./load";
import { START_STATE } from "../../constants";
import { isSafari } from "react-device-detect";

const INIT_STATE = {
  // Ask if they want to see the presentation
  ASK: 0,
  // Ask for the name to be said by the AI
  NAME: 1,
  // Load presentaion
  LOAD: 2,
};

/*
InitPresentation() asks whether the presentation should be played 
and connects to the text synthesize AI.
*/
export default function InitPresentation({ setStartState, setDone }) {
  const { getTranslation, hideSettings, setCanTransition, volume, language } =
    useSettings();
  const [nameVal, setNameVal] = useState("");
  const [aiStatus, setAiStatus] = useState(null);
  const [initState, setInitState] = useState(0);
  const [socketConn, setSocketConn] = useState(null);
  const customControls = useCustomControls((state) => state.controls);

  const onComplete = (ai) => {
    // zoom out
    setDone(true);
    if (ai) {
      const audio = new Audio("/audios/" + aiStatus.name + ".wav");
      audio.volume = volume;
      audio.play();
      audio.onended = () => {
        customControls.current.state.active = true;
      };
      /*
      PlayAudioWithReverb(aiStatus.name, volume, () => {
        customControls.current.state.active = true;
      });*/
    } else {
      customControls.current.state.active = true;
    }
  };

  if (initState === INIT_STATE.ASK) {
    return (
      <group>
        <p>
          {!isSafari ? getTranslation("recommend") : getTranslation("safari")}
        </p>
        <button
          disabled={isSafari}
          onClick={() => {
            customControls.current.state.startPage = false;
            setCanTransition(false);
            setInitState(INIT_STATE.NAME);
          }}
          id="start"
        >
          {getTranslation("start")}
        </button>
        <button
          onClick={() => {
            customControls.current.moveBack();
            customControls.current.state.active = true;
            hideSettings(1);
            setStartState(START_STATE.START);
          }}
        >
          {getTranslation("skip")}
        </button>
      </group>
    );
  } else if (initState === INIT_STATE.NAME) {
    return (
      <group>
        <p>{getTranslation("great")}</p>
        <p>{getTranslation("tellme")}</p>
        <input
          type="text"
          placeholder=""
          value={nameVal}
          onChange={(e) => {
            let value = e.target.value;

            value = value.replace(/[^A-Za-z]/gi, "");

            setNameVal(value);
          }}
        />
        <p id="note">{getTranslation("privacy")}</p>
        <button
          onClick={() => {
            var socket = new WebSocket(
              "wss://www.vonmutius.me/synText?" +
                new URLSearchParams({
                  text: nameVal,
                  lang: language,
                })
            );
            socket.onopen = () => {
              hideSettings(2);
              setInitState(INIT_STATE.LOAD);
              setSocketConn(socket);
            };
            socket.onmessage = (e) => {
              var answer = JSON.parse(e.data);
              setAiStatus(answer);
            };
            socket.onerror = (e) => {
              hideSettings(2);
              setInitState(INIT_STATE.LOAD);
            };
          }}
        >
          {getTranslation("continue")}
        </button>
      </group>
    );
  } else if (initState === INIT_STATE.LOAD) {
    return (
      <LoadPresentation
        socket={socketConn}
        aiStatus={aiStatus}
        callback={onComplete}
      />
    );
  }
}
