// index.js file
// main logic of this react app
// Copyright 2022 Alexander von Mutius

// Imports
import React, { useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { Canvas } from "@react-three/fiber";
import tunnel from "tunnel-rat";
import Portfolio from "./portfolioView/portfolio";
import { AdaptiveDpr, PerformanceMonitor } from "@react-three/drei";
import Source from "./sourceView/source";
import CustomControls, {
  useCustomControls,
} from "./portfolioView/customControls";
import { isFirefox, isMobile } from "react-device-detect";

// Foooter() displays my name on all pages ;)
function Footer() {
  return (
    <div id="sign">
      <p>Made by</p>
      <p>A. von Mutius</p>
    </div>
  );
}

// Create a new tunnel for html & canvas rendering
export const Html = tunnel();

/*
Main() is our entry component and also the one containing
our canvas as well as major webgl settings and the normal
html renderer over a tunnel
*/
function App() {
  const customControlRef = useRef();
  const { setControls } = useCustomControls();

  const [dpr, setDpr] = useState(1);

  const onMonitorChange = ({ factor }) => {
    // Calculate the dpr value within the range of 0.1 to 1
    const newDpr = Math.min(Math.max(0.1, 0.5 + 1.5 * factor), 1);
    setDpr(newDpr.toFixed(1));
  };

  useMemo(() => {
    setControls(customControlRef);
  }, [customControlRef]);

  if (isMobile) {
    window.screen.orientation.lock("landscape");
  }
  /*
  The tool used to generate the animmations ship them as parts
  with the model so i take all anim. and merge them onto one model
  */
  /*
  AnimMerge();
  return;
  */
  return (
    <group>
      {/* Canvas for 3D renderingÜ*/}
      <Canvas
        performance={{
          current: 1,
          min: 0.1,
          max: 1,
        }}
        dpr={dpr}
        // we enable shadows in general but don't use them in sourceView
        // but we can just disable casting shadows for lights
        shadows={"basic"}
        // For some reason invalidate() doesn't work for firefox.
        frameloop={isFirefox ? "always" : "demand"}
        // Set camera with viewport
        // Source: 0,0,140
        //camera={{ position: [1 * 20, 1 * 20, 1.5 * 20], fov: 80, far: 1000 }}
        camera={{ position: [0, 0, 140], near: 1, fov: 70, far: 10000 }}
        // Set some renderer settings for improving post processing and overall performance
        gl={{
          powerPreference: "high-performance",
          alpha: false,
          antialias: false,
          stencil: false,
          depth: false,
        }}
      >
        <PerformanceMonitor onChange={onMonitorChange} />
        <CustomControls ref={customControlRef} />
        <Portfolio />
        <Source />
        <AdaptiveDpr pixelated />
      </Canvas>
      {/* We are tunneling out the html here since they use the html renderer
        which is different to the canvas renderer*/}
      <Html.Out />
      <Footer />
    </group>
  );
}

// Render root
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
