import { useProgress } from "@react-three/drei";
import { Html } from "..";

export default function Loader() {
  const { progress } = useProgress();
  return (
    <Html.In>
      <div class="loader">
        <div class="loader-mask">
          <img
            style={{ top: "-" + progress.toString() + "%" }}
            id="loaderLogo"
          ></img>
        </div>
        <p>{Math.round(progress)}%</p>
      </div>
    </Html.In>
  );
}
