import { LoopOnce, Vector3 } from "three";
import * as THREE from "three";
import React, { useMemo, useEffect, useState, Suspense, useRef } from "react";
import useSettings from "../../useSettings";

import { useFrame, useThree } from "@react-three/fiber";

import { Html } from "../../index";
import { TO_SCALE } from "../../constants";
import { Preload } from "@react-three/drei";
import Loader from "../loading";

// Delay of the model entering the room
// and starting to speak
const entranceDelay = 5000;

/*
Transcription() shows the subtitles of the presentation
at the bottom of the screen
function Transcription({ start, animations }) {
  const getTranslation = useSettings((state) => state.getTranslation);
  const setCanTransition = useSettings((state) => state.setCanTransition);
  const [sentenceIndex, setSentenceIndex] = useState(1);
  const [isPlaying, setIsPlaying] = useState(true);
  const [startDelay, setStartDelay] = useState(entranceDelay);
  const [hideCon, setHideCon] = useState(true);
  const [anim, setAnim] = useState(1);
  const textRef = useRef();

  let duration = animations[anim - 1].duration;
  let sentence = getTranslation(`presentation${sentenceIndex}`);

  useFrame(({ clock }) => {
    if (!isPlaying) return;
    let index = Math.floor(
      ((clock.getElapsedTime() - startDelay) / duration) * sentence.length
    );
    index = Math.min(index, sentence.length);
    textRef.current.innerHTML = sentence.slice(0, index);
    if (index === sentence.length) {
      setIsPlaying(false);
      // Not continueable with spacebar but switching to sourceView
      if (sentenceIndex !== 4) setHideCon(false);
    }
  });

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === " " && !isPlaying && sentenceIndex < 4) {
        // set start delay to 0 again
        textRef.current.innerHTML = "";
        start(anim);
        setStartDelay(0);
        setSentenceIndex(sentenceIndex + 1);
        setIsPlaying(true);
        setHideCon(true);
        setAnim(anim + 1);
        // Activate transition to sourceView
        if (sentenceIndex === 3) {
          setCanTransition(true);
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isPlaying, sentenceIndex]);

  return (
    <Html.In>
      <div id="transcription">
        <div ref={textRef}></div>
        {!hideCon && (
          <div class="continue">{getTranslation("presentationContinue")}</div>
        )}
      </div>
    </Html.In>
  );
}
*/
function Transcription({ start, animations, audio }) {
  const getTranslation = useSettings((state) => state.getTranslation);
  const setCanTransition = useSettings((state) => state.setCanTransition);
  const [isPlaying, setIsPlaying] = useState(true);
  const [startDelay, setStartDelay] = useState(entranceDelay);
  const [hideCon, setHideCon] = useState(true);
  const [anim, setAnim] = useState(1);
  const [text, setText] = useState("");

  let duration = animations[anim - 1].duration;
  let sentence = getTranslation(`presentation${anim}`);

  audio.onpause = () => {
    setIsPlaying(false);
    // Not continueable with spacebar but switching to sourceView
    if (anim !== 4) setHideCon(false);
  };

  useEffect(() => {
    let index = 0;
    let timer = null;
    let lastTimeStamp = null;

    const play = (timeStamp) => {
      if (!lastTimeStamp) {
        lastTimeStamp = timeStamp;
      }
      let letterN = Math.floor(
        (timeStamp - lastTimeStamp) /
          (((duration - startDelay / 1000) / sentence.length) * 1000)
      );
      if (letterN > 1) {
        lastTimeStamp = timeStamp;
        if (index < sentence.length) {
          // Print next letter
          setText(sentence.slice(0, (index += letterN)));
          // Print letters at constant speed waiting for start delay
          // and calculating audio duration and sentence length
          requestAnimationFrame(play);
        }
      } else {
        requestAnimationFrame(play);
      }
    };

    if (isPlaying) {
      timer = setTimeout(requestAnimationFrame(play), startDelay);
    }

    const handleKeyPress = (event) => {
      if (event.key === " " && !isPlaying && anim < 4) {
        // set start delay to 0 again
        setText("");
        start(anim);
        setStartDelay(0);
        setIsPlaying(true);
        setHideCon(true);
        setAnim(anim + 1);
        // Activate transition to sourceView
        if (anim === 3) {
          setCanTransition(true);
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      clearTimeout(timer);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [isPlaying, anim]);

  return (
    <Html.In>
      <div id="transcription">
        <div>{text}</div>
        {!hideCon && (
          <div class="continue">{getTranslation("presentationContinue")}</div>
        )}
      </div>
    </Html.In>
  );
}
/*
Prensentation() renders the model and plays all animation clips
of the presentation
*/
export default function Presentation({ run }) {
  const getTranslation = useSettings((state) => state.getTranslation);
  const setInPresentation = useSettings((state) => state.setInPresentation);
  const model = useSettings((state) => state.model);
  const inPresentation = useSettings((state) => state.inPresentation);
  const { invalidate } = useThree();
  const modelRef = useRef();

  const [mixer, audio] = useMemo(() => {
    if (!run) return [];
    const mixer = new THREE.AnimationMixer(model.scene);
    const audio = new Audio(getTranslation("presentationAudio"));

    mixer.addEventListener("finished", function (e) {
      // Pause and wait for Transcription interaction
      audio.pause();
    });
    audio.oncanplay = () => {
      start(0);
    };
    return [mixer, audio];
  }, [run]);

  const start = (animCnt) => {
    if (animCnt === 4) return;
    const anim = model.animations[animCnt];
    let action = mixer.clipAction(anim);
    /*
    One of my tries to fix the positioning problem. Making all contagious clips additive.
    Unforunately this results in some clipping since the end hip position is what we want, but
    still slighty wrong for future arm movements

    if (animCnt > 0) {
      var clip = THREE.AnimationUtils.makeClipAdditive(model.animations[animCnt] );
      action = mixer.clipAction(clip);
      action.blendMode = AdditiveAnimationBlendMode;
    }
    */
    // End fix of the position problem. For more precise results, we get the hip position
    // of the next animation clip and sub it from the root position. This is the difference we
    // add to our last model position to make sure the hip seems to be at the same pos.
    // Only flaw is that leg and body position could be off.
    const hipTrack = anim.tracks.find((x) => x.name === "hip.position");
    const hipStartPositionValues = hipTrack.values.slice(0, 3);
    const hipStartPosition = new Vector3().fromArray(hipStartPositionValues);
    model.scene.updateMatrixWorld();
    model.scene.localToWorld(hipStartPosition);
    const diffVec = modelRef.current.position.clone().sub(hipStartPosition);
    var hip = model.scene.getObjectByName("hip");
    const hipPosition = new Vector3();
    hip.getWorldPosition(hipPosition);
    const hipEndPosition = new Vector3(
      hipPosition.x,
      modelRef.current.position.y,
      hipPosition.z
    );
    if (animCnt > 0) {
      modelRef.current.position.set(...hipEndPosition.add(diffVec));
      let prevAction = mixer.clipAction(model.animations[animCnt - 1]);
      prevAction.fadeOut(0);
    }
    // This will freeze the last frame which should prevent
    // teleportations
    action.clampWhenFinished = true;
    action.setLoop(LoopOnce);
    action.reset();
    audio.play();
    action.play();
    if (animCnt == 0) {
      setInPresentation(true);
    }
  };

  // This is needed so the animation continues playing frame by frame
  useFrame((state, delta) => {
    if (mixer != null) {
      mixer.update(delta);
      invalidate();
    }
  });

  return (
    <group>
      {model !== null && (
        <Suspense fallback={<Loader />}>
          <primitive
            visible={inPresentation}
            ref={modelRef}
            position={[1.8 * 20, 0.1 * 20, -0.5 * 20]}
            object={model.scene}
            scale={TO_SCALE(0.65)}
          />
          <Preload all />
        </Suspense>
      )}
      {inPresentation && (
        <Transcription
          audio={audio}
          start={start}
          animations={model.animations}
        />
      )}
    </group>
  );
}
