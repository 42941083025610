import { extend } from "@react-three/fiber";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import * as THREE from "three";
import { Euler, Vector3 } from "three";
/*
This is a custom scale i choose in blender to improve quality.
Unfortunately lights and shadows have many factors so even if everything refers here,
changing it, might break the scene
*/
const CUSTOM_SCALE = 20;

export const TO_SCALE = (v) => {
  return v * CUSTOM_SCALE;
};

export const LANGS = ["en", "de"];

// Render state enum
export const RENDER_TYPE = {
  PORTFOLIO: 0,
  BOTH: 1,
  SOURCE: 2,
};

// Start page state
export const START_STATE = {
  START: 0,
  INIT_PRESENTATION: 1,
  PRESENTATION: 2,
};

// Roof state enum
export const ROOF_TYPE = {
  OPENED: 0,
  SHOULD: 1,
  CLOSED: 2,
};

/*
Camera offsets
*/
export const PORTFOLIO_START_OFFSET = new Vector3(1, 1, 1.5).multiplyScalar(
  CUSTOM_SCALE
);

// Upwards quaternion used by the roof, universe, source. Not 90° so we won't flip the camera
export const UPWARDS_QUATERNION_OFFSET = new Euler(1.5507989928217463, 0, 0);

export const SKY_HEIGHT = TO_SCALE(100);

export const SOURCE_OFFSET = new Vector3(
  20,
  2019.6002498107837,
  -9.985541818224945
);

export const NODE_CAM_DISTANCE = TO_SCALE(5);

/*
These are pivot points for the different walls
*/
export const START_OFFSET = new THREE.Vector3();
export const LANG_OFFSET = new THREE.Vector3();
export const CV_OFFSET = new THREE.Vector3();
export const PROJECT_OFFSET = new THREE.Vector3();
export const ROOF_OFFSET = new THREE.Vector3(1, 1.5, 1.5).multiplyScalar(
  CUSTOM_SCALE
);

/*
Resource paths
*/
// Models
export const ROOM_PATH = "../models/room_low.glb";
export const ROOF_PATH = "../models/roof.glb";
// Textures
export const SCALP_PATH = "../models/textures/scalp_trans.jpg";
export const EYE_PATH = "../models/textures/eye_trans.jpg";

// Tooltip shortening
export const WithTooltip = ({ ID, name, info }) => {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 50, hide: 250 }}
      overlay={<Tooltip>{info}</Tooltip>}
    >
      <div id={ID}>{name}</div>
    </OverlayTrigger>
  );
};
