import React, { useRef, useMemo, useEffect, useState, Suspense } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import { MathUtils, MeshPhysicalMaterial, MeshStandardMaterial } from "three";
import {
  Bloom,
  EffectComposer,
  LUT,
  Vignette,
} from "@react-three/postprocessing";
import {
  Html,
  BakeShadows,
  CubeCamera,
  Preload,
  ContactShadows,
} from "@react-three/drei";
import { degToRad } from "three/src/math/MathUtils";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";
import useSettings from "../useSettings";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Lights from "./lights";
import {
  PORTFOLIO_START_OFFSET,
  RENDER_TYPE,
  ROOF_PATH,
  ROOF_TYPE,
  ROOM_PATH,
  START_STATE,
  TO_SCALE,
} from "../constants";
import { WithTooltip } from "../constants";
import { extendCamera, useCustomControls } from "./customControls";
import Settings from "./settings";
import InitPresentation from "./presentation/init";
import Presentation from "./presentation/presentation";
import Loader from "./loading";
import { isMobile } from "react-device-detect";
import { LUTCubeLoader } from "postprocessing";
import { Lightmap } from "@react-three/lightmap";
import { Distortion } from "tone";

export function Postprocessing() {
  const texture = useLoader(LUTCubeLoader, "../images/LUT.cube");
  return (
    <EffectComposer>
      <Vignette />
      <Bloom
        luminanceThreshold={0.1}
        mipmapBlur
        luminanceSmoothing={0.7}
        intensity={1}
      />
      <LUT lut={texture}></LUT>
    </EffectComposer>
  );
}

function Roof() {
  const {
    setRoof,
    hideSettings,
    getTranslation,
    showSettings,
    canTransition,
    inPresentation,
    setInPresentation,
    setModel,
    model,
    setRender,
    roof,
  } = useSettings();
  const controls = useCustomControls((state) => state.controls);
  const roofObj = useLoader(GLTFLoader, ROOF_PATH);
  const { camera, invalidate } = useThree();

  useMemo(() => {
    roofObj.scene.traverse(function (object) {
      if (object.isMesh) {
        object.castShadow = true;
        object.receiveShadow = true;
        object.material.roughness = 1;
      }
    });
  }, [roofObj.scene]);

  const disposeModel = (model) => {
    model.scene.traverse((object) => {
      if (object.isMesh) {
        object.geometry.dispose();
        if (!Array.isArray(object.material)) {
          object.material.dispose();
        } else {
          for (var i = 0; i < object.material.length; i++) {
            object.material[i].dispose();
          }
        }
      }
    });
    setModel(null);
  };

  const group1 = useRef();
  const group2 = useRef();

  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const onToggle = () => {
    if (!canTransition) return;
    if (model != null) {
      disposeModel(model);
    }
    setIsClicked(true);
    setRender(RENDER_TYPE.BOTH);
    if (inPresentation) {
      setInPresentation(false);
      showSettings(2);
    }
    controls.current.state.startPage = true;
    controls.current.state.active = false;
    hideSettings(1);
    open(true, performance.now());
  };

  const onEnter = () => {
    setIsHovered(true);
  };

  const onLeave = () => {
    setIsHovered(false);
  };

  const open = (dir, lastTime) => {
    const targetPos1 = group1.current.position.clone();
    const targetPos2 = group2.current.position.clone();
    targetPos1.setX(TO_SCALE(dir ? -1 : 0));
    targetPos2.setX(TO_SCALE(dir ? 3 : 2));

    // Define threshold value
    const threshold = 0.5;

    // Get current time
    const currentTime = performance.now();

    // Calculate delta time
    const deltaTime = (currentTime - lastTime) / 1000; // Convert milliseconds to seconds

    // Check distance between current and target position
    const dist1 = group1.current.position.distanceTo(targetPos1);

    // Lerp the position of the groups towards the target position if the distance is greater than the threshold
    if (dist1 > threshold) {
      // Define the speed of the animation
      const speed = 1; // Units per second

      // Calculate the distance to move the groups during this frame
      const distance = speed * deltaTime;
      group1.current.position.lerp(targetPos1, distance);
      group2.current.position.lerp(targetPos2, distance);
      invalidate();
      requestAnimationFrame(() => open(dir, currentTime));
    } else if (dir) {
      setRoof(ROOF_TYPE.OPENED);
      controls.current.moveTo(extendCamera(camera, 100), TO_SCALE(0.5), () => {
        setRender(RENDER_TYPE.SOURCE);
      });
    } else {
      setRender(RENDER_TYPE.PORTFOLIO);
      setRoof(ROOF_TYPE.CLOSED);
    }
  };

  useEffect(() => {
    if (roof === ROOF_TYPE.SHOULD) {
      open(false, performance.now());
    }
  });

  const style = {
    color:
      roof === ROOF_TYPE.SHOULD || isClicked
        ? "#00d0ff"
        : isHovered
        ? "#00d0ff"
        : "#161616",
  };

  const roof1 = roofObj.scene.clone(true);
  const roof2 = roofObj.scene.clone(true);

  return (
    <group>
      <group
        position={[TO_SCALE(roof < ROOF_TYPE.CLOSED ? -1 : 0), 2.04 * 20, 0]}
        ref={group1}
      >
        <Html
          position={[0.865 * 20, 0, 1.4 * 20]}
          transform
          rotation={[MathUtils.degToRad(90), 0, 0]}
        >
          <div
            className="starsP"
            onClick={onToggle}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            style={style}
          >
            {getTranslation("roofText1")}
          </div>
        </Html>
        <primitive scale={[1.1, 1, 1.2]} object={roof1} dispose={null} />
      </group>
      <group
        position={[TO_SCALE(roof < ROOF_TYPE.CLOSED ? 3 : 2), 2.04 * 20, 0]}
        ref={group2}
      >
        <Html
          position={[-0.865 * 20, 0, 1.4 * 20]}
          transform
          rotation={[MathUtils.degToRad(90), 0, 0]}
        >
          <div
            className="starsP"
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            onClick={onToggle}
            style={style}
          >
            {getTranslation("roofText2")}
          </div>
        </Html>
        <primitive scale={[-1.1, 1, 1.2]} object={roof2} dispose={null} />
      </group>
    </group>
  );
}

function Start() {
  const getTranslation = useSettings((state) => state.getTranslation);
  const inPresentation = useSettings((state) => state.inPresentation);
  const { camera } = useThree();
  const [startState, setStartState] = useState(START_STATE.START);
  const [done, setDone] = useState(false);
  const { controls } = useCustomControls();

  // Subscribe to changes in the store
  useSettings((state) => state.language);

  return (
    <group>
      {/* In html we can conditionally render stuff */}
      <Html
        className="aboutContainer"
        transform
        position={[1 * 20, 1 * 20, -0.82 * 20]}
      >
        {!inPresentation && (
          <div id="aboutContent">
            {startState === START_STATE.START && (
              <>
                <p
                  id="me"
                  dangerouslySetInnerHTML={{ __html: getTranslation("me") }}
                />
                <button
                  disabled={isMobile}
                  onClick={() => {
                    setStartState(START_STATE.INIT_PRESENTATION);
                    controls.current.state.active = false;
                  }}
                >
                  {!isMobile
                    ? getTranslation("seeMore")
                    : getTranslation("mobile")}
                </button>
              </>
            )}
            {startState === START_STATE.INIT_PRESENTATION && (
              <InitPresentation
                camera={camera}
                setStartState={setStartState}
                setDone={setDone}
              />
            )}
          </div>
        )}
        <div id="socials">
          <a />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/alexander-von-mutius-8436b0248/"
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/avmutius"
          />
        </div>
      </Html>
      <Presentation run={done} />
    </group>
  );
}

function LangInfo() {
  return (
    <Html
      className="cvContainer"
      transform
      position={[2.43 * 20, 0.93 * 20, 1.53 * 20]}
      rotation={[0, degToRad(-90), 0]}
    >
      {["Go", "C#", "JS, HTML, CSS", "C", "C++", "Perl", "Python", "React"].map(
        (str, i) => (
          <p key={"cv_" + str} className="langP">
            {str} :
          </p>
        )
      )}
    </Html>
  );
}

function CV() {
  const { getTranslation } = useSettings();

  return (
    <Html
      className="cvContainer"
      transform
      position={[1 * 20, 1 * 20, 2.96 * 20]}
      rotation={[0, degToRad(180), 0]}
    >
      <div className="cvRow">
        <div>{getTranslation("secondarySchool")}</div>
        <div id="cvGym">{getTranslation("college")}</div>
        <div>{getTranslation("telekom")}</div>
        <div id="cvBoreus">{getTranslation("boreus")}</div>
      </div>
      <div className="cvRow">
        <div>AKA</div>
        <div>LeagueFire</div>
        <div id="cvKutsu">KutshShin</div>
        <div>ZenitXGames</div>
      </div>
      <div id="cvDates" className="cvRow">
        <div>2016</div>
        <div>2017</div>
        <div>2018</div>
        <div>2019</div>
        <div>2020</div>
        <div>2021</div>
        <div>2022</div>
      </div>
    </Html>
  );
}

function AboutProject() {
  const { getTranslation } = useSettings();

  return (
    <Html
      className="cvContainer"
      transform
      position={[-0.43 * 20, 0.89 * 20, 1.48 * 20]}
      rotation={[0, degToRad(90), 0]}
    >
      <div className="projDesc">{getTranslation("projectDesc1")}</div>
      <div
        className="projDesc"
        dangerouslySetInnerHTML={{ __html: getTranslation("projectDesc2") }}
      ></div>
      <div className="projCircles">
        <WithTooltip ID={"cJs"} name={"JS"} info="Javascript" />
        <WithTooltip ID={"cAI"} name={"AI"} info="Artifical Intelligence" />
        <WithTooltip ID={"cGo"} name={"GO"} info="Go" />
        <WithTooltip ID={"cPl"} name={"PL"} info="Perl" />
        <WithTooltip ID={"cRct"} name={"RJS"} info="React Js" />
        <WithTooltip ID={"cPy"} name={"PY"} info="Python" />
        <WithTooltip ID={"cCss"} name={"CSS"} info="Css" />
      </div>
    </Html>
  );
}

/*
Loads the room glb model and sets shadow properties
*/
function Room() {
  const [cubeTex, setCubeTex] = useState();
  const room = useLoader(GLTFLoader, ROOM_PATH, (loader) => {
    loader.setMeshoptDecoder(MeshoptDecoder);
  });
  useMemo(() => {
    if (isMobile) return;
    room.scene.traverse(function (object) {
      if (object.isMesh) {
        // I didn't like how this texture turned out
        // so i replace it with a nicer one
        if (object.material.name === "Material3") {
          object.material = new MeshStandardMaterial({
            color: "red",
            toneMapped: "false",
          });
          // Make lamp reflecting
        } else if (object.material.name === "Lamp") {
          object.material = new MeshPhysicalMaterial({
            color: "#0f0e00",
            roughness: 0.01,
            envMap: cubeTex,
            metalness: 0.01,
            name: object.material.name,
          });
          // Real colors
        } else if (object.material.name === "Twitter") {
          object.material = new MeshStandardMaterial({
            color: "#00acee",
          });
          object.castShadow = true;
          object.receiveShadow = true;
        } else if (object.material.name === "Indeed") {
          object.material = new MeshStandardMaterial({
            color: "#2164f4",
          });
          object.castShadow = true;
          object.receiveShadow = true;
        } else if (object.material.name === "Linkedin") {
          object.material = new MeshStandardMaterial({
            color: "#0072b1",
          });
          object.castShadow = true;
          object.receiveShadow = true;
        } else {
          // Should not reflect any light
          object.material.roughness = 0.9;
          object.castShadow = true;
          object.receiveShadow = true;
        }
        object.matrixAutoUpdate = false;
      }
    });
  }, [room, cubeTex]);

  return (
    <>
      <CubeCamera position={[2.2 * 20, 0.7 * 20, 0.3 * 20]}>
        {(texture) => setCubeTex(texture)}
      </CubeCamera>
      <primitive object={room.scene} dispose={null} />
    </>
  );
}

/*
Portfolio is the main component of this file
*/
export default function Portfolio() {
  // Merge animations of models

  const render = useSettings((state) => state.render);
  const { camera } = useThree();

  useMemo(() => {
    camera.position.set(
      PORTFOLIO_START_OFFSET.x,
      PORTFOLIO_START_OFFSET.y,
      PORTFOLIO_START_OFFSET.z
    );
    // Initially zoomed at start wall
    var amount = 1.4;
    var movePos = extendCamera(camera, amount);
    camera.position.set(movePos.x, movePos.y, movePos.z);
  }, []);

  useEffect(() => {
    if (render !== RENDER_TYPE.PORTFOLIO) return;
    camera.fov = 80;
    camera.far = 1000;
    camera.updateProjectionMatrix();
  }, [render]);

  if (render == RENDER_TYPE.SOURCE) return;

  return (
    <group visible={render !== RENDER_TYPE.SOURCE}>
      <Suspense fallback={<Loader />}>
        {/* Overall lighting in black for a dark look*/}
        {/* Render the room object */}
        <Room />
        <Postprocessing />
        <Lights />
        {/* Bake all static shadows (room shadows) */}
        <ContactShadows
          resolution={1024}
          frames={1}
          position={[0, -1.16, 0]}
          scale={15}
          blur={0.5}
          opacity={1}
          far={20}
        />
        <BakeShadows />
        {/* Display wall contents */}
        <Start />
        {!isMobile && (
          <>
            <LangInfo />
            <CV />
            <AboutProject />
            <Roof />
            <Settings />
          </>
        )}
        <Preload all />
      </Suspense>
    </group>
  );
}
