/*
source.js renders the source, version control of this project

Copyright 2022 Alexander von Mutius
*/

// Imports
import * as THREE from "three";
import React, {
  useContext,
  Suspense,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { act, Canvas, extend, useFrame, useThree } from "@react-three/fiber";
import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib";
import {
  EffectComposer,
  Bloom,
  Noise,
  Vignette,
} from "@react-three/postprocessing";
import { OrbitControls, Html } from "@react-three/drei";
import { MeshLineGeometry, MeshLineMaterial } from "meshline";
import Options from "./options";
import "../css/main.css";
import useSettings from "../useSettings";
import {
  NODE_CAM_DISTANCE,
  RENDER_TYPE,
  ROOF_TYPE,
  SOURCE_OFFSET,
  TO_SCALE,
  UPWARDS_QUATERNION_OFFSET,
} from "../constants";
import { useCustomControls } from "../portfolioView/customControls";
import Settings from "./settings";
import { isMobile } from "react-device-detect";

// This lets us access API not exposed by three fiber which wraps around three.js
extend({ MeshLineGeometry, MeshLineMaterial });

RectAreaLightUniformsLib.init();

/*
Stars() randomly renders stars in the background with a default count of 5000
*/
function Stars({ count = 1000 }) {
  const colorArray = useMemo(() => {
    let colors = [];
    for (let i = 0; i < count; i++) {
      colors.push(Math.random());
      colors.push(Math.random());
      colors.push(Math.random());
    }
    return new Float32Array(colors);
  }, [count]);
  const positions = useMemo(() => {
    let positions = [];
    for (let i = 0; i < count; i++) {
      const r = 4000;
      const theta = 2 * Math.PI * Math.random();
      const phi = Math.acos(2 * Math.random() - 1);
      const x =
        r * Math.cos(theta) * Math.sin(phi) + (-2000 + Math.random() * 4000);
      const y =
        r * Math.sin(theta) * Math.sin(phi) + (-2000 + Math.random() * 4000);
      const z = r * Math.cos(phi) + (-1000 + Math.random() * 2000);
      positions.push(x);
      positions.push(y);
      positions.push(z);
    }
    return new Float32Array(positions);
  }, [count]);
  return (
    <points position={[20, 500, 30]}>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          attach={"attributes-position"}
          count={positions.length / 3}
          array={positions}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-color"
          count={colorArray.length / 3}
          array={colorArray}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial size="12" toneMapped={false} vertexColors />
    </points>
  );
}

/*
Line() just draws a line from A to B with given color and opacity
A and B need to be an array of X, Y, Z
*/
function Line({ start, end, color, opacity }) {
  const points = [];
  points.push(new THREE.Vector3(start[0], start[1], start[2]));
  points.push(new THREE.Vector3(end[0], end[1], end[2]));
  return (
    <mesh>
      <meshLineGeometry points={points} />
      <meshLineMaterial
        opacity={opacity}
        transparent
        lineWidth={0.4}
        emissiveIntensity={0.5}
        color={color}
        depthWrite={false}
        toneMapped={false}
      />
    </mesh>
  );
}

/*
Node() renders one node + the line to its parent
Param node needs an node object
Param onClick needs a click function handler
*/
function Node({ node, onClick }) {
  // Reference the group
  const ref = useRef();

  // We access our orbitcontrols here exposed by makeDefault
  const controls = useThree((state) => state.controls);

  // Active color
  const activeColor = "#ff726f";
  // Get color: we diff between module, folder and file
  var color = "white";
  if (node.IsDir) {
    color = "#ffff80";
  }
  if (node.IsMod != null) {
    // Only the module object has a language param
    color = "lightblue";
  }

  var vec = new THREE.Vector3();
  // in frame loop
  useFrame(() => {
    ref.current.getWorldPosition(vec);
    // floating point problems
    if (node.Selected && controls.target.x.toFixed(2) != vec.x.toFixed(2)) {
      // lerp for a smooth animation
      controls.target.lerp(vec, 0.01);
      // update orbitcontrols
      controls.update();
    }
  });

  return (
    <group>
      <group onClick={onClick} ref={ref} position={node.Position}>
        <Html
          position={[0, 4, 0]}
          zIndexRange={[100, 0]}
          center
          distanceFactor={10}
        >
          <div className="nodeTitle">{node.Name}</div>
        </Html>
        <mesh>
          <sphereGeometry attach="geometry" args={[2, 64, 64]} />
          <meshStandardMaterial
            attach="material"
            transparent
            emissive={node.Active ? activeColor : color}
            emissiveIntensity={1}
            toneMapped={false}
            size={100}
          />
        </mesh>
      </group>
      <Line
        start={node.Parent != null ? node.Parent.Position : [0, 0, 0]}
        end={node.Position}
        color={node.Active ? activeColor : "white"}
      />
    </group>
  );
}

/* getLeaves() needs a node object and will calculate all ancestor
without child objects => leaves of a tree
Returns the count min = 1
*/
function getLeaves(node) {
  var count = 0;
  if (node.Children != null && node.Children.length > 0) {
    node.Children.map((c) => {
      if (c.Children == null || c.Children.length == 0) {
        count++;
      } else {
        count += getLeaves(c);
      }
    });
  } else {
    count++;
  }
  return count;
}

/*
RadialPositionNodes() positions all ancestor children of the root in a radial manner
taking care of spacing and distance
Param node needs an node objcet
Param alfa is the start angle
Param beta is the amount of circle to use
Param nodeRadius is the radius between parent and child nodes
Param distnace is the distance between child nodes
Param nodes is the array which gets filled with all radial positioned nodes
*/
function RadialPositionNodes(node, alfa, beta, nodeRadius, distance, nodes) {
  // First iteration, node must be root
  if (nodes.length == 0) {
    // Important for positioning
    node.Position = [0, 0, 0];
    node.Parent = null;
    node.Level = 0;
    // Default selected node
    node.Selected = true;
    node.Active = true;
    nodes.push(node);
  }
  // depth of the node / generation of every child
  const depth = node.Level;
  var theta = alfa;
  // calculate radius based on depth and distance
  const radius = nodeRadius + distance * depth;
  // get leaves (nodes without child) of parent
  var leaves = getLeaves(node);

  node.Children.map((n) => {
    // get leaves (nodes without child) of child
    var lambda = getLeaves(n);
    // calculate angle based on remaining space
    var mi = theta + (lambda / leaves) * (beta - alfa);

    var x = radius * Math.cos((theta + mi) / 2.0);
    var z = radius * Math.sin((theta + mi) / 2.0);

    // set child and push it to array
    n.Position = [x, 0, z];
    n.Parent = node;
    n.Level = node.Level + 1;
    nodes.push(n);

    // continue if we have more children
    if (n.Children != null && n.Children.length > 0) {
      RadialPositionNodes(n, theta, mi, nodeRadius, distance, nodes);
    }
    // increase start angle
    theta = mi;
  });
}

/*
NodeSystem() renders our "planet system" but with nodes.
*/
function NodeSystem(nodes) {
  // fetch our modules and nodes
  const [nodesState, setNodesState] = useState([]);
  const [activeNode, setActiveNode] = useState({});
  const curProject = useSettings((state) => state.curProject);
  // Create our array for drawing the nodes
  var nodes = [];

  useEffect(() => {
    fetch(
      "/getProject?" +
        new URLSearchParams({
          project: curProject,
        })
    )
      .then((res) => res.json())
      .then((rootNode) => {
        // No modules? => critical bug
        if (rootNode == null) return;
        // Calculate the nodes for drawing in a radial position around root
        RadialPositionNodes(rootNode, 0, 2 * Math.PI, 40, 15, nodes);
        // Set root node to active
        setActiveNode(rootNode);
        // Set the node state
        setNodesState(nodes);
      });
  }, curProject);

  // update node object states on click
  const updateActiveNode = (id) => {
    // create copy so state changes are recognized
    let nodes = [...nodesState];
    nodes.map((n) => {
      n.Selected = false;
      n.Active = false;
    });
    var parent = nodes[id];
    // selected node
    parent.Selected = true;
    // set active node
    setActiveNode(parent);
    // show the node path from first to last node
    while (parent) {
      parent.Active = true;
      parent = parent.Parent;
    }
    // set to nodes state
    setNodesState(nodes);
  };

  return (
    <group>
      <group>
        {/* Use nodestate to receive updates*/}
        {nodesState.map((node, i) => (
          <Node key={i} node={node} onClick={() => updateActiveNode(i)} />
        ))}
      </group>
      {activeNode && <Options node={activeNode} />}
    </group>
  );
}

/*
Postprocessing() puts some after effects on our renderer
like for example glowing
*/
function Postprocessing() {
  return (
    <EffectComposer multisampling={0} disableNormalPass={true}>
      <Noise opacity={0.025} />
      <Bloom
        intensity={0.5}
        luminanceThreshold={0}
        luminanceSmoothing={0.025}
        kernelSize={3}
      />
      <Vignette eskil={false} offset={0.1} darkness={1.1} />
    </EffectComposer>
  );
}

/*
Source is the main component of this file
*/
export default function Source() {
  // set camera properties for the universe and planet system
  const { camera } = useThree();
  const render = useSettings((state) => state.render);

  useEffect(() => {
    if (render == RENDER_TYPE.PORTFOLIO) {
      return;
    }
    camera.fov = 70;
    camera.far = 10000;
    camera.updateProjectionMatrix();
  }, [render]);

  if (isMobile) return;

  const nodePos = SOURCE_OFFSET.clone().setY(
    SOURCE_OFFSET.y - NODE_CAM_DISTANCE
  );

  // load background texture
  return (
    <group visible={render !== RENDER_TYPE.PORTFOLIO} position={nodePos}>
      {/* Set background of canvas */}
      <color attach="background" args={["#100c14"]} />
      {render == RENDER_TYPE.SOURCE && (
        <group>
          <OrbitControls
            target={nodePos}
            maxDistance={NODE_CAM_DISTANCE + TO_SCALE(2.5)}
            minDistance={NODE_CAM_DISTANCE - TO_SCALE(2.5)}
            camera={camera}
            makeDefault
            enableDamping
          />
          <NodeSystem />
          <Settings />
        </group>
      )}
      <Stars />
      <Postprocessing />
    </group>
  );
}
